import { useState, useEffect } from "react";
import pointSerivce from "./points.service";

const usePoints = () => {
  const [points, setPoints] = useState();
  const [docId, setDocId] = useState();

  useEffect(() => {
    pointSerivce.getPoints().then(({ id, points }) => {
      setDocId(id);
      setPoints(points);
    });
  }, []);

  const updatePoints = (points) => {
    if (!docId) return;
    return pointSerivce.updatePoints(docId, points).then(() => setPoints(points));
  };

  return [points, updatePoints];
};

export default usePoints;
