import db from "./firebase";

const pointSerivce = {
  getPoints() {
    return db
      .collection("points")
      .get()
      .then((querySnapshot) => querySnapshot.docs[0])
      .then((doc) => ({ id: doc.id, points: doc.data().points }));
  },

  updatePoints(id, points) {
    return db.collection("points").doc(id).update({ points });
  },
};

export default pointSerivce;
