import firebase from "firebase";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyCouo0ZCGdrvZZ_kakuhxzkpUIxF8P0Fcc",
  authDomain: "points-60388.firebaseapp.com",
  databaseURL: "https://points-60388.firebaseio.com",
  projectId: "points-60388",
  storageBucket: "points-60388.appspot.com",
  messagingSenderId: "682461828968",
  appId: "1:682461828968:web:52644218a6a0bd44f1a8dd",
  measurementId: "G-6X4WC3LSPM",
});

const db = firebase.firestore();

export default db;
