import { useState } from "react";
import usePoints from "./points.hook";

const App = () => {
  const [points, setPoints] = usePoints();
  const [pendingRequest, setPendingRequest] = useState(false);

  if (points === undefined) return <div>⏳</div>;

  const updatePoints = (newPoints) => {
    if (pendingRequest) return;
    setPendingRequest(true);
    setPoints(newPoints).then(() => setPendingRequest(false));
  };

  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "960px",
        width: "100%",
      }}
    >
      <p className="text-4xl m-4 w-11/12">Points:</p>
      <p className="text-9xl w-11/12 p-8 text-center">{points}</p>
      <button
        onClick={() => updatePoints(points + 1)}
        className="block m-4 w-11/12 py-2 px-4 font-semibold rounded-lg shadow-md bg-green-500 hover:bg-green-700"
      >
        {pendingRequest ? "⏳" : "Increment"}
      </button>
      <button
        onClick={() => updatePoints(points - 1)}
        className="block m-4 w-11/12 py-2 px-4 font-semibold rounded-lg shadow-md bg-red-500 hover:bg-red-700"
      >
        {pendingRequest ? "⏳" : "Decrement"}
      </button>
    </div>
  );
};

export default App;
